import {errorHttpMessage} from "@/utils/errorDecodeUtil"
import operationClient from "@/axiosClients/operationClient"
import dashboardClient from '@/axiosClients/dashboardClient'

export const getFilters = async ({ commit }) => {
    try{        
        const { data:result } = await operationClient.get(`/get-filters/dashboard`)        
        const { data, message, error } = result.result    
        return {data, message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getDashboardInfo = async ({ commit }, params) => {
    try{        
        const { data:result } = await dashboardClient.get(`/periodic-exams${params}`)              
        return result.result
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const downloadPdfDashboard = async ({commit}, data) => {
    try{        
        // const { data:result }= await dashboardClient.post(`/download-pdf`, data)   
        const {data: result} = await dashboardClient({
            url: `/download-pdf`,
            method: "POST",
            responseType: "blob",
            data
        }) 
        let blob = new Blob([result], {type: "application/pdf"});
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.target = "_blank"
        link.click();
        document.body.removeChild(link);
        return result    
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}

export const getDataGraphClosedDays = async ({ commit }, params) => {
    try{        
        const { data:result } = await dashboardClient.get(`/closed-days-data${params}`)              
        return result.result
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}
